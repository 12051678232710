/* POS.css */

body {
    background-color: #121212;
    color: #e0e0e0;
    font-family: Arial, sans-serif;
}

.pos-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #1e1e1e; /* Dark background */
}

.product-list, .cart, .keypad, .checkout {
    width: 24%;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 5px;
    background: #2a2a2a; /* Darker background for boxes */
    color: #e0e0e0; /* Light text for contrast */
}

.product-item {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    text-align: left;
    background: #333; /* Dark background for each item */
    border: 1px solid #444;
    color: #e0e0e0;
    cursor: pointer;
    transition: background 0.3s ease;
}

.product-item:hover {
    background: #555; /* Lighter shade on hover */
}

.keypad button, .checkout-button {
    width: 100%;
    padding: 15px;
    margin: 5px 0;
    font-size: 1.2em;
    background: #007bff; /* Blue color for buttons */
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.keypad button:hover, .checkout-button:hover {
    background: #0056b3; /* Darker blue on hover */
}

input, select, button {
    background-color: #2a2a2a;
    color: #e0e0e0;
    border: 1px solid #444;
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
}

input::placeholder {
    color: #888; /* Light placeholder color */
}

/* Scrollbar customization */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: #444; /* Dark scrollbar thumb */
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #666; /* Lighter on hover */
}
