.workflow-designer {
    display: flex;
    height: 100vh;
    width: 100vw;
    font-family: 'Arial', sans-serif;
}

.sidebar {
    width: 300px;
    padding: 20px;
    background-color: #2c3e50;
    color: #ecf0f1;
    display: flex;
    flex-direction: column;
    z-index: 2;
    /* Ensure it's clickable and not overlapped */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar h4,
.sidebar h5 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #ecf0f1;
}

.sidebar label {
    font-size: 14px;
    color: #bdc3c7;
    margin-bottom: 5px;
    display: block;
}

.sidebar input,
.sidebar select,
.sidebar button {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: none;
    border-radius: 4px;
    background-color: #34495e;
    color: #ecf0f1;
    font-size: 14px;
    box-sizing: border-box;
    z-index: 2;
    /* Make sure inputs and buttons are tappable */
}

.sidebar input:focus,
.sidebar select:focus,
.sidebar button:focus {
    outline: none;
    background-color: #3b4f61;
}

.sidebar .add-property-button {
    background-color: #1abc9c;
    color: white;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 20px;
}

.sidebar .add-property-button:hover {
    background-color: #16a085;
}

.property-list {
    background-color: #34495e;
    border-radius: 4px;
    padding: 10px;
    max-height: 150px;
    overflow-y: auto;
}

.property-list ul {
    list-style-type: none;
    padding: 0;
}

.property-list li {
    font-size: 14px;
    margin-bottom: 10px;
    color: #ecf0f1;
}

.diagram-area {
    flex-grow: 1;
    height: 100%;
    background-color: #ecf0f1;
    z-index: 1;
    /* Ensure diagram area is below the sidebar */
    padding: 20px;
}